import React, { Component, memo } from "react";
import Jumbotron from "./JobOfferRedesignedJumbotron/JobOfferRedesignedJumbotron";
import Information from "./Information/Information";
import SendCVWidget from "./SendCVWidget/SendCVWidget";
import "./_job-offer-redesigned.scss";
import Benefits from "./Benefits/Benefits";
import OpenMainCarousel from "./MainCarousel/OpenMainCarousel/OpenMainCarousel";
import mainCarouselDefaultHeroImg from "../../../assets/img/default-carousel-hero-img.jpg";
import DocumentTitle from "../../common/DocumentTitle/DocumentTitle";
import { stringifyCities } from "../../../common/utils";
import GetToKnowUs from "./GetToKnowUs/GetToKnowUs";
import SlickSlider from "../../common/SlickSlider/SlickSlider";
import photoArr from "../../../../src/data/jobOfferPhotoSet";
import BackButtonFooter from "./BackButtonFooter/BackButtonFooter";
import MediaQuery from "react-responsive";

class JobOfferRedesigned extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMainCarouselOpen: false,
    };
  }

  changeMainCarouselStatus = () => {
    this.setState((prevState) => ({
      isMainCarouselOpen: !prevState.isMainCarouselOpen,
    }));
  };

  render() {
    const { offer, gaTracking, ga4Tracking, recruiterDetails } = this.props;

    return (
      offer && (
        <div className="JobOfferRedesignedWrapper">
          <DocumentTitle title={`${offer.title} - ${stringifyCities(offer.cities)}`} />
          <article className="JobOfferRedesigned">
            <div className="JobOfferRedesigned-firstColumn">
              <Jumbotron
                title={offer.title}
                salaryB2B={offer.salaryB2B}
                salaryEmploymentContract={offer.salaryEmploymentContract}
                featureImg={offer.ogImage}
                requiredSkills={offer.details.technicalSkills.required}
                optionalSkills={offer.details.technicalSkills.optional}
              />
              {offer.details.introduction && (
                <Information
                  title="Hello, let’s meet!"
                  content={offer.details.introduction}
                  type="text"
                  isAboutCompany={true}
                />
              )}
              {offer.aboutProject && (
                <Information title="About Project" content={offer.aboutProject} type="text" />
              )}
              <Information title="You will be:" content={offer.responsibilities} type="list" />
              <Information title="Your profile:" content={offer.requirements} type="list" />
              {offer.niceToHave && (
                <Information title="Nice to have:" content={offer.niceToHave} type="list" />
              )}
              {offer.recruitmentProcess && (
                <Information
                  title="Recruitment Process"
                  content={offer.recruitmentProcess}
                  type="list"
                />
              )}
              {offer.slider && (
                <OpenMainCarousel
                  bgcImage={offer.mainCarouselHeroImg || mainCarouselDefaultHeroImg}
                  handleChange={this.changeMainCarouselStatus}
                />
              )}
              <GetToKnowUs />
              {!offer.hideBenefits && <Benefits benefitsType={offer.benefits_type} />}
            </div>
            <div className="JobOfferRedesigned-secondColumn">
              <div className="JobOfferRedesigned-fixedWrapper">
                <SendCVWidget
                  offer={offer}
                  recruiter={offer.jobOfferPatron}
                  gaTracking={gaTracking}
                  ga4Tracking={ga4Tracking}
                  recruiterDetails={recruiterDetails}
                />
              </div>
            </div>
            <MediaQuery maxDeviceWidth={1023}>
              <BackButtonFooter />
            </MediaQuery>
          </article>
          <SlickSlider photos={photoArr} />
        </div>
      )
    );
  }
}

export default memo(JobOfferRedesigned);
