import React from "react";
import "./_benefits.scss";
import polishBenefits from "../../../../data/polishBenefits.json";
import bulgarianBenefits from "../../../../data/bulgarianBenefits.json";

import Benefit from "./Benefit/Benefit";

function Benefits({ benefitsType }) {
  const displayBenefits = () => {
    const benefits = benefitsType === "bulgaria" ? bulgarianBenefits : polishBenefits;

    return (
      <div className="Benefits-listOfBenefits">
        <div className="Benefits-column">
          {benefits
            .filter((_, index) => index % 2 == 0)
            .map((benefit, index) => (
              <Benefit key={index} content={benefit} />
            ))}
        </div>
        <div className="Benefits-column">
          {benefits
            .filter((_, index) => index % 2 !== 0)
            .map((benefit, index) => (
              <Benefit key={index} content={benefit} />
            ))}
        </div>
      </div>
    );
  };

  return (
    <section className="Benefits">
      <h2 className="Benefits-title">Benefits</h2>
      {displayBenefits(benefitsType)}
    </section>
  );
}

export default Benefits;
