import React from "react";
import MediaQuery from "react-responsive";
import "./_job-offers-cities.scss";
import { citiesInPoland, citiesAbroad } from "../../../../../common/utils";

const showCitiesOnDesktop = (cities) => {
  if (!cities) return <></>;

  const onlyCities = cities.filter((city) => city.toUpperCase() !== "REMOTE");
  const isRemote = cities.findIndex((item) => item.toUpperCase() === "REMOTE") !== -1;
  const foreignCities = cities.filter((city) =>
    citiesAbroad.some((foreignCiti) => city === foreignCiti)
  );
  const allOffices = citiesInPoland;

  if (allOffices.every((pgsCity) => onlyCities.includes(pgsCity))) {
    return (
      <React.Fragment>
        <div className="JobOffersCities-briefInfoWrapper">
          <span className="JobOffersCities-hiddenInfo">{allOffices.join(", ")}</span>
          <span className="JobOffersCities-city JobOffersCities-allOffersBadge">All offices</span>
          {foreignCities.map((foreignCity, index) => (
            <span key={`${index}-${foreignCity}`} className="JobOffersCities-city">
              {foreignCity}
            </span>
          ))}
        </div>
        {isRemote && <span className="JobOffersCities-city">Remote</span>}
      </React.Fragment>
    );
  }
  return cities.map((city, index) => (
    <span key={`${index}-${city}`} className="JobOffersCities-city">
      {city}
    </span>
  ));
};

const showCitiesOnMobile = (cities) => {
  if (!cities) return <></>;

  return cities.map((city, index) => (
    <span key={`${index}-${city}`} className="JobOffersCities-city">
      {city}
    </span>
  ));
};

const JobOffersCities = ({ cities }) => (
  <React.Fragment>
    <MediaQuery minDeviceWidth={1025}>
      <div className="JobOffersCities" aria-hidden={true}>
        {showCitiesOnDesktop(cities)}
      </div>
    </MediaQuery>
    <MediaQuery maxDeviceWidth={1023.999}>
      <div className="JobOffersCities" aria-hidden={true}>
        {showCitiesOnMobile(cities)}
      </div>
    </MediaQuery>
  </React.Fragment>
);

export default JobOffersCities;
