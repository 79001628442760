import React from "react";
import { JSONLD, Generic } from "react-structured-data";

import benefits from "../../../data/benefits.json";
import { MICROFORMATS } from "../../../constants/Constants";

import { removeHtmlTags } from "../../../common/utils";

const JobMicroformats = (props) => (
  <JSONLD>
    <Generic
      type="jobposting"
      jsonldtype="JobPosting"
      schema={{
        hiringOrganization: MICROFORMATS.XEBIA.NAME,
        industry: MICROFORMATS.XEBIA.INDUSTRY,
        title: props.offer.title,
        skills: props.offer.details.technicalSkills.required.map((skill) => skill.name).join(),
        description: removeHtmlTags(props.offer.details.basicInformation),
        datePosted: props.offer.date,
        jobBenefits: benefits.map((benefit) => benefit.label).join(),
      }}
    >
      <Generic
        type="jobLocation"
        jsonldtype="Place"
        schema={{
          address: props.offer.cities?.join(),
        }}
      />
    </Generic>
  </JSONLD>
);

export default JobMicroformats;
