import { chain } from "lodash";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import PageHeader from "../../common/PageHeader/PageHeader";
import Spinner from "../../common/Spinner/Spinner";
import { withNavigate } from "../../helpers";
import FiltersModal from "../JobOffersFilter/FiltersModal/FiltersModal";
import JobOffersMainFilter from "../JobOffersFilter/JobOffersMainFilter/JobOffersMainFilter";
import JobOffersSearchBar from "../JobOffersFilter/JobOffersSearchBar/JobOffersSearchBar";
import OpenFiltersOnMobileButton from "../JobOffersFilter/OpenFiltersOnMobileButton/OpenFiltersOnMobileButton";
import { deNormalizeCities, deNormalizeTech } from "./../../../common/utils";
import JobOffersList from "./JobOffersList/JobOffersList";
import NoResults from "./NoResults/NoResults";
import "./_job-offers.scss";

class JobOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOffers: this.props.allOffers,
      values: this.props.initialValues,
      selectedCities: this.props.offersFilters.cities,
      selectedTechnologies: this.props.offersFilters.technologies,
      offersSelectedFromSearchbar: this.props.offers,
      offersFetched: this.props.offersFetched,
      width: 0,
      showFiltersOnMobile: false,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      values: [...nextProps.offersFilters.technologies, ...nextProps.offersFilters.cities],
      selectedCities: [...nextProps.offersFilters.cities],
      selectedTechnologies: [...nextProps.offersFilters.technologies],
      allOffers: nextProps.allOffers,
      offersSelectedFromSearchbar: nextProps.offers,
      offersFetched: nextProps.offersFetched,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.offersFilters.cities !== prevProps.offersFilters.cities ||
      this.props.offersFilters.technologies !== prevProps.offersFilters.technologies
    ) {
      this.pushNewUrl(this.props.offersFilters.cities, this.props.offersFilters.technologies);
    }
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  onFilter = (field, newValue, oldValue) => {
    const citiesFilter =
      this.props.offersFilters.cities === undefined ? [] : this.props.offersFilters.cities;
    const technologiesFilter =
      this.props.offersFilters.technologies === undefined
        ? []
        : this.props.offersFilters.technologies;

    const {
      addTechnologyToFilter,
      removeTechnologyFromFilter,
      addCityToFilter,
      removeCityFromFilter,
    } = this.props.actions;

    switch (field) {
      case "city":
        removeCityFromFilter(oldValue);
        if (newValue !== "All") {
          addCityToFilter(newValue);
        }
        break;
      case "technology":
        removeTechnologyFromFilter(oldValue);
        if (newValue !== "All") {
          addTechnologyToFilter(newValue);
        }
        break;
      default:
        break;
    }

    this.pushNewUrl(citiesFilter, technologiesFilter);
  };

  pushNewUrl(citiesFilter, technologiesFilter) {
    const citiesFilterString = deNormalizeCities(citiesFilter);
    const technologiesFilterString = technologiesFilter.map((tech) =>
      deNormalizeTech(tech, this.props.technologiesNormalized)
    );

    const citiesFilterSendToUrl =
      citiesFilterString === undefined || citiesFilterString.length === 0
        ? ""
        : `/${citiesFilterString}`;
    const technologiesFilterSendToUrl =
      technologiesFilterString === undefined || technologiesFilterString.length === 0
        ? ""
        : `/${technologiesFilterString}`;

    const newUrl =
      citiesFilterSendToUrl || technologiesFilterSendToUrl
        ? `${citiesFilterSendToUrl}${technologiesFilterSendToUrl}`
        : "/";

    this.props.navigate(newUrl, {
      replace: true,
    });
  }

  getTechnologiesFormOffers(offers) {
    return chain(offers)
      .map((offer) => offer.technology)
      .uniq()
      .value();
  }

  getOffersKeys = (offers, key) => {
    const results = [];
    offers.map((item) => {
      results.push(item[key]);
    });
    return results;
  };

  onRemove = (value) => {
    this.state.selectedCities.indexOf(value) > -1 &&
      this.setState({
        selectedCities: this.state.selectedCities.filter((element) => element != value),
      });

    this.state.selectedTechnologies.indexOf(value) > -1 &&
      this.setState({
        selectedTechnologies: this.state.selectedTechnologies.filter((element) => element != value),
      });

    this.setState({
      values: [...this.state.selectedTechnologies, ...this.state.selectedCities],
    });
  };

  removeAllValues = () => {
    this.setState({
      values: [],
      selectedCities: [],
      selectedTechnologies: [],
    });
  };

  useSearchBar = (keywords) => {
    const modyfiedKeywords = keywords
      .toUpperCase()
      .replaceAll(/-/g, "")
      .replaceAll(/,/g, "")
      .split(" ")
      .filter((modyfiedKeyword) => modyfiedKeyword.length > 0);

    let newOffersSelectedFromSearchbar = this.props.offers;

    if (modyfiedKeywords.length > 0) {
      newOffersSelectedFromSearchbar = newOffersSelectedFromSearchbar.filter((offer) => {
        const modyfiedTitle = offer.title.toUpperCase().replace(/-/g, "").replaceAll(/,/g, "");

        const modyfiedSkills = [
          ...offer.details.technicalSkills.required,
          ...offer.details.technicalSkills.optional,
        ].map((skill) => skill.name.toUpperCase());

        for (let i = 0; i < modyfiedKeywords.length; i++) {
          if (modyfiedTitle.includes(modyfiedKeywords[i])) {
            return true;
          }
          for (let j = 0; j < modyfiedSkills.length; j++) {
            if (modyfiedSkills[j].includes(modyfiedKeywords[i])) {
              return true;
            }
          }
        }
      });
    }

    this.setState({
      offersSelectedFromSearchbar: newOffersSelectedFromSearchbar,
    });
  };

  showFiltersOnMobile = () => {
    this.setState((prevState) => ({
      showFiltersOnMobile: !prevState.showFiltersOnMobile,
    }));
  };

  render() {
    const technologiesFromOffers = ["All", ...this.props.technologiesFromOffers];
    const citiesForFilter = [
      "All",
      "Gdańsk",
      "Rzeszów",
      "Wrocław",
      "UK",
      "Remote",
      "Germany",
      "Belgium",
      "Warszawa",
      "Kraków",
      "Bulgaria",
      "Moldova",
      "Romania",
      "London",
      "Switzerland",
    ];
    return (
      <div className="JobOffers ready" aria-hidden={this.props.cvModalOpen}>
        <PageHeader cvModalOpen={this.props.cvModalOpen} />
        <div className="JobOffers-content">
          <MediaQuery minWidth={1025}>
            <div className="JobOffers-filters">
              <JobOffersSearchBar
                searchForAnOffers={this.useSearchBar}
                cvModalOpen={this.props.cvModalOpen}
              />
              <JobOffersMainFilter
                field="technology"
                values={technologiesFromOffers}
                onSelect={this.onFilter}
                initialValue={this.state.selectedTechnologies}
                cvModalOpen={this.props.cvModalOpen}
              />
              <JobOffersMainFilter
                field="city"
                values={citiesForFilter}
                onSelect={this.onFilter}
                initialValue={this.state.selectedCities}
                cvModalOpen={this.props.cvModalOpen}
              />
              <a
                href="https://xebia.com/career-poland/referral-program/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={"Refer a friend"}
                className={`JobOffers-refer-button Btn Btn-fill`}
              >
                <span>Refer a friend</span>
              </a>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={1024}>
            <div className="JobOffers-filters">
              <JobOffersSearchBar searchForAnOffers={this.useSearchBar} />
              <OpenFiltersOnMobileButton showFiltersOnMobile={this.showFiltersOnMobile} />
            </div>
            {this.state.showFiltersOnMobile ? (
              <FiltersModal
                showFiltersOnMobile={this.showFiltersOnMobile}
                onSelect={this.onFilter}
                technologiesFromOffers={technologiesFromOffers}
                selectedCities={this.state.selectedCities}
                selectedTechnologies={this.state.selectedTechnologies}
                citiesForFilter={citiesForFilter}
              />
            ) : null}
            <div className="JobOffers-filters-bottom">
              <a
                href="https://xebia.com/career-poland/referral-program/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={"Refer a friend"}
                className={`JobOffers-refer-button Btn Btn-fill`}
              >
                <span>Refer a friend</span>
              </a>
            </div>
          </MediaQuery>
          {this.state.offersFetched ? (
            this.state.offersSelectedFromSearchbar.length > 0 ? (
              <JobOffersList
                offers={this.state.offersSelectedFromSearchbar}
                technologiesNormalized={this.props.technologiesNormalized}
                cvModalOpen={this.props.cvModalOpen}
              />
            ) : (
              <NoResults />
            )
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    );
  }
}

export default withNavigate(JobOffers);
